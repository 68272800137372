import {BrowserRouter as Router } from 'react-router-dom'
import AnimatedRoutes from "./AnimatedRoutes";

function RoutesWrapper() {

    return (
        <Router>
            <AnimatedRoutes />
        </Router>
    )
}

export default RoutesWrapper;