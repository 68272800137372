import { Route, Routes, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';
// import React, { Suspense } from 'react';
import loadable from '@loadable/component';

const Homepage = loadable(() => import('../home/Homepage'))

const GoalsTools = loadable(() => import('../pages/about/GoalsTools'))
const Leadership = loadable(() => import('../pages/about/leadership/LeadershipComposite'))
const Personnel = loadable(() => import('../pages/about/personnel/PersonnelComposite'))
const Collaborators = loadable(() => import('../pages/about/collaborators/CollaboratorsComposite'))
const Press = loadable(() => import('../pages/about/press/PressComposite'))
const VisualMedia = loadable(() => import('../pages/about/media/VisualMediaComposite'))
const Jobs = loadable(() => import('../pages/about/jobs/JobsComposite'))

const Elephants = loadable(() => import('../pages/programs/Elephants'))
const Pangolins = loadable(() => import('../pages/programs/Pangolins'))
const Timber = loadable(() => import('../pages/programs/Timber'))
const IUUFishing = loadable(() => import('../pages/programs/IUUFishing'))
const Pathogens = loadable(() => import('../pages/programs/Pathogens'))
const ContainerSearches = loadable(() => import('../pages/programs/ContainerSearches'))
const Software = loadable(() => import('../pages/programs/software/SoftwareComposite'))
const Publications = loadable(() => import('../pages/programs/publications/PublicationsComposite'))


// Insert Education Routes //
const CK9 = loadable(() => import('../pages/education/ConservationCanines'))

const ContactUs = loadable(() => import('../pages/connect/ContactUs'))
const FundUs = loadable(() => import('../pages/connect/FundUs'))
const Donors = loadable(() => import('../pages/connect/Donors'))

const NotFound404 = loadable(() => import('./NotFound'))

const SearchComponents = loadable(() => import('../navigation/SearchComponents'))

function AnimatedRoutes() {

    const location = useLocation()

    return(

        <AnimatePresence>

            <Routes location={location} key={location.pathname}>

                <Route path="/" element={<Homepage />}></Route>

                <Route path="/about/goals-tools" element={<GoalsTools />}></Route>
                {/*<Route path="/about/focal-species" element={<FocalSpecies />}></Route>*/}
                <Route path="/about/leaders" element={<Leadership />}></Route>
                <Route path="/about/members" element={<Personnel />}></Route>
                <Route path="/about/partners" element={<Collaborators />}></Route>
                <Route path="/about/press" element={<Press />}></Route>
                <Route path="/about/visual-media" element={<VisualMedia />}></Route>
                <Route path="/about/jobs" element={<Jobs />}></Route>

                <Route path="/programs/elephants" element={<Elephants />}></Route>
                <Route path="/programs/pangolins" element={<Pangolins />}></Route>
                <Route path="/programs/timber" element={<Timber />}></Route>
                <Route path="/programs/iuu-fishing" element={<IUUFishing />}></Route>
                <Route path="/programs/pathogens" element={<Pathogens />}></Route>
                <Route path="/programs/container-searches" element={<ContainerSearches />}></Route>
                <Route path="/programs/software" element={<Software />}></Route>
                <Route path="/programs/publications" element={<Publications />}></Route>

                {/* Insert Education Routes */}
                <Route path="/education/ck9" element={<CK9 />}></Route>
                <Route path="/conservation-canines" element={<CK9 />}></Route>

                <Route path="/connect/contact-us" element={<ContactUs />}></Route>
                <Route path="/connect/fund-us" element={<FundUs />}></Route>
                {/*<Route path="/connect/common-qa" element={<CommonQA />}></Route>*/}
                <Route path="/connect/donors" element={<Donors />}></Route>

                <Route path="/search" element={<SearchComponents />}></Route>

                <Route path="*" element={<NotFound404 />}></Route>

            </Routes>
            
        </AnimatePresence>
    )
}

export default AnimatedRoutes;