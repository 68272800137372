import { ParallaxProvider } from 'react-scroll-parallax';
import RoutesWrapper from './components/routes/RoutesWrapper'

function App() {

  return (
    <ParallaxProvider>
      <RoutesWrapper />
    </ParallaxProvider>
  );
}

export default App;